$mat-primary: (
  100: #eff9ff,
  500: #0074ba,
  700: #006daf,
  contrast: (
    100: rgba(black, 0.87),
    500: white,
    700: white,
  ),
);
$mat-secondary: (
  100: #9cb7dd,
  500: #78a0d1,
  700: #5089c6,
  contrast: (
    100: white,
    500: white,
    700: white,
  ),
);

$mat-success: (
  100: #c1ecda,
  500: #a0e2c7,
  700: #7dd8b5,
  900: #55cda3,
  contrast: (
    100: white,
    500: white,
    700: white,
  ),
);

$mat-warn: (
100: #ffacb2,
  500: #ff8f9a,
  700: #fc4b6c,
  contrast: (
    100: white,
    500: white,
    700: white,
  ),
);


$theme-primary: mat.define-palette($mat-primary, 500);
$theme-accent: mat.define-palette($mat-secondary, 500);
$theme-warn: mat.define-palette($mat-warn, 500);

$theme-success: mat.define-palette($mat-success, 500);

$aquatheme: mat.define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    typography:
      mat.define-typography-config(
        $font-family: "DM Sans",
      ),
  )
);

$darkaquatheme: mat.define-dark-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    typography:
      mat.define-typography-config(
        $font-family: "DM Sans",
        $subtitle-2: mat.define-typography-level(14px, 14px, 400),
        $subtitle-1: mat.define-typography-level(21px, 26px, 500),
        $headline-6: mat.define-typography-level(18px, 26px, 600),
        $headline-5: mat.define-typography-level(24px, 30px, 700),
        $button: mat.define-typography-level(14px, 14px, 400),
        $body-1: mat.define-typography-level(14px, 20px, 400),
        $body-2: mat.define-typography-level(16px, 22px, 400),
      ),
  )
);

.bg-primary {
  background: mat.get-color-from-palette($theme-primary, 500) !important;
}

.bg-warn {
  background: mat.get-color-from-palette($theme-warn, 500) !important;
}

.bg-active {
  background: mat.get-color-from-palette($mat-secondary, 100) !important;
}

.horizontal-navbar .parentBox.pactive > a,
.horizontal-navbar .parentBox.pactive > a:hover,
.horizontal-navbar .parentBox.pactive:hover > a {
  background-color: mat.get-color-from-palette($theme-accent, 500) !important;
}

.activeMenu {
  background-color: mat.get-color-from-palette($theme-accent, 500) !important;
}



.mat-mdc-outlined-button {
  &.mat-primary {
    border-color: mat.get-color-from-palette($theme-primary, 500) !important;
  }
  &.mat-accent {
    border-color: mat.get-color-from-palette($theme-accent, 500) !important;
  }
  &.mat-warn {
    border-color: mat.get-color-from-palette($theme-warn, 500) !important;
  }
}
