/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.betkit-container {
  position: relative;
  min-height: 200px;
}

.betkit-table-container {
  position: relative;
  max-height: 600px;
  overflow: auto;
}

.betkit-loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.betkit-rate-limit-reached {
  color: #980000;
  max-width: 360px;
  text-align: center;
}

.right-align-header {
  text-align: right;
}
.mat-mdc-header-cell {
  text-align: center;
  justify-content: center; /* Ensures alignment in a flex container */
}

.th-right-header {
  display: table-cell !important;
  text-align: right !important;
  justify-content: first baseline !important;
  min-width: 100px !important;
  white-space: nowrap !important;
  padding-right: 16px !important;
}
.th-right {
  text-align: right !important;
  justify-content: flex-end !important;
  padding-right: 16px !important;
  padding-right: 16px !important;
}

/* General header cell and content alignment */
.th-right-header {
  text-align: right !important;
  justify-content: flex-end !important;
  padding-right: 16px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.th-right-header .mat-sort-header-container {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
  padding-right: 16px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.th-right-header .mat-sort-header-arrow {
  margin-left: 4px !important;
  flex-shrink: 0 !important;
  align-self: center !important;
}

.th-right-header .mat-sort-header-sorted .mat-sort-header-arrow {
  transform: translateY(0) !important;
}

/* Align the cell content for right-aligned columns */
.th-right {
  text-align: right !important;
  padding-right: 16px !important;
  white-space: nowrap !important;
}

/* Specific style for the Rate column to handle long decimal numbers */
.mat-column-rate {
  text-align: right !important;
  padding-right: 16px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  min-width: 100px !important;
  max-width: 180px !important;
}

/* General column width constraints */
.mat-column-code,
.mat-column-reference_code,
.mat-column-source,
.mat-column-action {
  min-width: 100px !important;
  max-width: 150px !important;
}
.success-snackbar {
  background-color: green !important;
  color: white !important;
}

.error-snackbar {
  background-color: red !important;
  color: white !important;
}
.mat-mdc-snack-bar-container {
  &.success-snackbar {
    --mdc-snackbar-container-color: rgb(18, 94, 18);
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
}
.mat-mdc-snack-bar-container {
  &.error-snackbar {
    --mdc-snackbar-container-color: rgb(204, 38, 38);
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
}
.margin-top-button{
  margin-top: 6px;
}
.selected-stake {
  background-color: #1976d2 !important; /* Change this to your desired color */
  color: white !important; /* Change text color if needed */
}

.stake-buttons button.active {
  background-color: #e0e0e0 !important; /* Optional: Background color for active button */
}

.margin-left-button{
  margin-left: 6px !important;
}

.custom-table {
  border-collapse: separate;
  width: 100%;
  table-layout: auto;
 font-size: small;
  th,
  td {
    padding: 0.4rem 0.6rem;
    text-align: center;
  }

  tr {
    border-bottom: 2px solid #ddd;
  }

  th:nth-child(even),
  td:nth-child(even) {
    background-color: #ececec;
  }

  th:nth-child(odd),
  td:nth-child(odd) {
    background-color: #ddd;
  }

  tbody {
    .desc {
      display: flex;
      flex-direction: column;
      gap: 0.1rem;
      align-items: start;
      text-align: start !important;
    }
  }

  a {
    color: #2d89ce;
  }
}

.active-row {
  background-color: #e0e0e0 !important;
  font-weight: bold !important;
}

.fetch-button {
  padding: 0 16px !important;
  border-radius: 6px !important;
  font-size: 12px !important;
  height: 44px !important;
}

.flex-container {
  display: flex;
  align-items: center;
  gap: 8px; /* Space between form field and button */
  margin-top: 7px !important;
}

.small-form-field {
  width: 50%; /* Reduce width to minimize 20% from left */
  margin-left: 20px; /* Add margin to push it from the left */
  height: 47px; /* Keep height consistent */
}

.search-button {
  border-radius: 5px !important;
  font-size: 12px !important;
  height: 47px !important; /* Match height with form field */
  line-height: 1;
  padding: 8px !important;

}


mat-form-field.small {
  --mat-form-field-container-height: 30px;
  --mat-form-field-container-vertical-padding: 12px;
}

.search-container {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.exposure-container {
  text-align: center !important;
  font-weight: bold !important;
  margin: 0 20px !important;
}

.select-column {
  width: 40px !important; /* Adjust width as needed */
  padding: 0 !important; /* Optional: Reduce padding if necessary */
}
.exposure-label {
  font-weight: bold !important;
  font-size: 1.3em !important; /* Increase the font size as needed */
  color: #333; /* Optional: Adjust the color */
}

.stake-setting {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  .stake-config {
    display: flex;
    justify-content: space-between;
    .stake-inputs {
      display: flex;
      flex-wrap: wrap;
      gap: 0.2rem;
      width: 90%;
      input {
        max-width: 130px;
        text-align: right;
        min-height: 44px;
        //border-radius: 0.25em;
      }
      span {
        min-width: 130px;
        min-height: 44px;
        display: inline-block;
        padding: 0.4em 0.2em 0.4em;
        margin-bottom: 0.4em;
        //color: #333;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 0.25em;
        //background-color: #ddd;
        cursor: pointer;
        font-size: 11px;
      }
      .active-stake {
        background-color: #777;
        color: #fff;
      }
    }
    .stake-edit-btn {
      border: 0.1em solid #e5e5e5;
      padding: 0.3em 8px;
      color: #333;
      cursor: pointer;
      line-height: 1;
    }
  }
  .stake-save-btn {
    font-weight: 700;
    cursor: pointer;
    border: 1px solid #e5e5e5;
    padding: 4px 5px;
    font-size: 14px;
    align-self: center;
  }
}


.row-won {
  background-color: #e6ffe6 !important;
}

.row-lost {
  background-color: #ffcccc !important;
}


