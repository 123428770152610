$mat-primary: (
  100: #f3c9bd,
  500: #2a3447,
  700: #dd7454,
  contrast: (
    100: white,
    500: white,
    700: white,
  ),
);
$mat-secondary: (
  100: #2a3447,
  500: #2a3447,
  700: #2a3447,
  contrast: (
    100: white,
    500: white,
    700: white,
  ),
);

$mat-warn: (
  100: #fc4b6c,
  500: #fc4b6c,
  700: #fc3258,
  contrast: (
    100: white,
    500: white,
    700: white,
  ),
);

$mat-active: (
  100: #b3b6bd,
  500: #8e939e,
  700: #6b7180,
  contrast: (
    100: white,
    500: white,
    700: white,
  ),
);

$theme-primary: mat.define-palette($mat-primary, 500);
$theme-accent: mat.define-palette($mat-secondary, 500);
$theme-warn: mat.define-palette($mat-warn, 500);

$theme-active: mat.define-palette($mat-active, 500);


$orangetheme: mat.define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
      active: $theme-active,
    ),
    typography:
      mat.define-typography-config(
        $font-family: "DM Sans",
        $subtitle-2: mat.define-typography-level(14px, 14px, 400),
        $subtitle-1: mat.define-typography-level(21px, 26px, 500),
        $headline-6: mat.define-typography-level(18px, 26px, 600),
        $headline-5: mat.define-typography-level(24px, 30px, 700),
        $button: mat.define-typography-level(14px, 14px, 400),
        $body-1: mat.define-typography-level(14px, 20px, 400),
        $body-2: mat.define-typography-level(16px, 22px, 400),
      ),
  )
);

$darkorangetheme: mat.define-dark-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    typography:
      mat.define-typography-config(
        $font-family: "DM Sans",
        $subtitle-2: mat.define-typography-level(14px, 14px, 400),
        $subtitle-1: mat.define-typography-level(21px, 26px, 500),
        $headline-6: mat.define-typography-level(18px, 26px, 600),
        $headline-5: mat.define-typography-level(24px, 30px, 700),
        $button: mat.define-typography-level(14px, 14px, 400),
        $body-1: mat.define-typography-level(14px, 20px, 400),
        $body-2: mat.define-typography-level(16px, 22px, 400),
      ),
  )
);

.bg-primary {
  background: mat.get-color-from-palette($theme-primary, 500) !important;
}

.bg-warn {
  background: mat.get-color-from-palette($theme-warn, 500) !important;
}

.bg-active {
  background: mat.get-color-from-palette($theme-active, 500) !important;
}

.dark-theme {
  .bg-active {
    background: #324f23 !important;
  }
  .bg-warn {
    background: #7e3737 !important;
  }
  .blue_theme .bg-active {
    background: #3a4960 !important;
  }
  .blue_theme .bg-warn {
    background: #a34b56 !important;
  }
  .aqua_theme .bg-active {
    background: #66768d !important;
  }
  .aqua_theme .bg-warn {
    background: #a34b56 !important;
  }

  .pink_theme .bg-active {
    background: #c9b2c0 !important;
  }
  .pink_theme .bg-warn {
    background: #b95e68 !important;
  }
  .purple_theme .bg-active {
    background: #b6adc7 !important;
  }
  .purple_theme .bg-warn {
    background: #b3676e !important;
  }

  .green_theme .bg-active {
    background: #657876 !important;
  }
  .green_theme .bg-warn {
    background: #784045 !important;
  }

}


.horizontal-navbar .parentBox.pactive > a,
.horizontal-navbar .parentBox.pactive > a:hover,
.horizontal-navbar .parentBox.pactive:hover > a {
  background-color: mat.get-color-from-palette($theme-accent, 500) !important;
}

.activeMenu {
  background-color: mat.get-color-from-palette($theme-accent, 500) !important;
}

.mat-mdc-outlined-button {
  &.mat-primary {
    border-color: mat.get-color-from-palette($theme-primary, 500) !important;
  }
  &.mat-accent {
    border-color: mat.get-color-from-palette($theme-accent, 500) !important;
  }
  &.mat-warn {
    border-color: mat.get-color-from-palette($theme-warn, 500) !important;
  }
}

.mat-button-toggle-appearance-standard {
  background: none !important;
}

a {
  color: #000000 !important;
}

